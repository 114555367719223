import React, { useContext } from "react"
import { ThemeContext } from "styled-components"
import { graphql } from "gatsby"
import { findFontWeightAtInterval } from "../../utils/utils"
import Box from "../atoms/Box"
import { Text } from "../atoms/Typography"
import Button from "../atoms/Button"

const generateAlphabet = () => {
  const alphabet = []
  for (let i = 65; i <= 90; i++) {
    // ASCII values for A-Z from 65 to 90
    alphabet.push(String.fromCharCode(i))
  }
  return alphabet
}

const AlphabeticalFilter = ({
  selectedAlphaLetterFilter,
  setSelectedAlphaLetterFilter,
}) => {
  const theme = useContext(ThemeContext)
  const currentTextFontWeight = theme.typography.text.fontWeight
  const alphabetList = generateAlphabet()

  return (
    <Box
      className="alpha-filter"
      display="flex"
      justifyContent="center"
      flexWrap="wrap"
      marginTop="28px"
      marginBottom="48px"
    >
      {alphabetList.map(letter => (
        <Button
          aria-label="Filter by letter button"
          variant="link"
          hideArrow={true}
          margin={{
            _: "10px 13.5px",
          }}
          onClick={() => {
            if (letter === selectedAlphaLetterFilter) {
              setSelectedAlphaLetterFilter("")
            } else {
              setSelectedAlphaLetterFilter(letter)
            }
          }}
          textAlign="left"
          key={letter}
          style={{ cursor: "pointer" }}
        >
          <Text
            opacity={selectedAlphaLetterFilter === letter ? 1 : 0.8}
            fontSize={3}
            fontWeight={{
              _:
                selectedAlphaLetterFilter === letter
                  ? findFontWeightAtInterval({
                      fontWeight: currentTextFontWeight,
                      positionInterval: 2,
                      fontWeightsObject: theme.fontWeights,
                    })
                  : currentTextFontWeight,
            }}
          >
            {letter}
          </Text>
        </Button>
      ))}
    </Box>
  )
}

export default AlphabeticalFilter

export const query = graphql`
  fragment AlphabeticalFilter on STRAPI__COMPONENT_ATOMS_ALPHABETICAL_FILTER {
    useFilter
    alphabeticalFilterBy
  }
`
